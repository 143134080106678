import React, { useState } from 'react';
import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import '/node_modules/primeflex/primeflex.css'
//import { Button } from 'primereact/button';
//import { Card } from 'primereact/card';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
//import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Keycloak from 'keycloak-js';
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip'; 

let initOptions = {
  url: 'https://login.devops-genie.com/',
  realm: 'sso-provider',
  clientId: 'react-client',
  onLoad: 'login-required', // check-sso | login-required
  KeycloakResponseType: 'code',

  // silentCheckSsoRedirectUri: (window.location.origin + "/silent-check-sso.html")
}

let kc = new Keycloak(initOptions);

kc.init({
  onLoad: initOptions.onLoad,
  KeycloakResponseType: 'code',
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", checkLoginIframe: false,
  pkceMethod: 'S256'
}).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    console.info("Authenticated");
    console.log('auth', auth)
    console.log('Keycloak', kc)
    kc.onTokenExpired = () => {
      console.log('token expired')
    }
  }
}, () => {
  console.error("Authenticated Failed");
});



function App() {

  const [infoMessage, setInfoMessage] = useState('');

  
  const images = ['argo-icon.png', 'jenkins-icon.png', 'netdata-icon.png',
  'grafana-icon.png', 'metabase-icon.png', 'nexus-icon.png',
  'gitlab_icon.png', 'app.png'
  ];

  const names = ['Argo', 'Jenkins', 'Netdata',
  'Grafana', 'Metabase', 'Nexus',
  'GitLab','MyApp'
  ];

  const links = ['https://34.124.222.111/', 'http://34.143.198.216/', 'http://35.247.149.167:19999/',
  'http://34.143.233.12:3000', 'http://34.126.128.247:3000/', 'http://34.87.45.115:8081',
  'https://35.198.238.32','http://34.87.93.61:5000'
  ];

  const Quote = ['Argo CD is a declarative, GitOps continuous delivery tool for Kubernetes',
'Jenkins is a highly popular DevOps tool used by thousands of development teams',
'Monitoring agent that collects thousands of hardware and software metrics from any physical or virtual system',
'Grafana open source software enables you to query, visualize, alert on, and explore your metrics, logs, and traces wherever they are stored',
'Metabase is a simple and powerful analytics tool which lets anyone learn and make decisions from their companys data',
'Sonatype Nexus Repository is a robust package registry for all of your Docker images and Helm Chart repositories. ',
'GitLab is a web-based Git repository that allows software development teams to plan, code, test, deploy and monitor product changes in one place',
'My sample app']
  
const tooltip = (index) => (
  <Tooltip id="tooltip">
    {Quote[index]}
  </Tooltip>
);

  const handleClick = (index) => {
    
    
    //window.location.replace(links[index]);
    window.open(links[index], '_blank');
    //window.location.replace('https://bobbyhadz.com');
  };


  const jenkinsheader  = (<img alt="Card" src="jenkins.png" />);
  const jenkinsfooter = (
    <>
        <a href="http://34.142.186.9:8080">click me</a>
        
    </>);
    const gitlabheader  = (<img alt="Card" src="gitlab.png" />);
    const gitlabfooter = (
      <>
          <a href="http://35.198.238.32">click me</a>
      </>);
      const argoheader  = (<img alt="Card" src="argo.png" />);
      const argofooter = (
        <>
            <a href="https://34.124.222.111/">click me</a>
        </>);
        const nexusheader  = (<img alt="Card" src='nexus.png' />);
        const nexusfooter = (
          <>
              <a class="button" href="http://34.87.45.115:8081/">click me</a>
          </>);
  return (
    <>
    <div>

    </div>
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="#home"><h1>Code-Genie</h1></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#features"></Nav.Link>
                <Nav.Link href="#pricing"></Nav.Link>
                
              </Nav>
              <Nav>
                <Nav.Link href="#deets">Welcome</Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                  
                {!!kc.authenticated && (
                   <button
                     onClick={() => kc.logout()}
                   >
                     Logout ({kc.tokenParsed.preferred_username})
                   </button>
                 )}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
     <Row xs={1} md={4} className="s-4">
        {Array.from({ length: 8 }).map((_, idx) => (
          <Col key={idx}>
            <Card>
              <Card.Img variant="top" src={images[idx]} />
              <Card.Body>
                
               
                <OverlayTrigger 
        delay={{ hide: 450, show: 300 }} 
        overlay={(props) => ( 
          <Tooltip {...props}> 
            {Quote[idx]}
          </Tooltip> 
        )} 
        placement="bottom"><button variant="primary" size="lg" onClick={()=>handleClick(idx)}>{names[idx]}</button>
      </OverlayTrigger>, 
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
    ); 
}
        {/*<div className="col">
        <Button onClick={() => { setInfoMessage(kc.authenticated ? 'Authenticated: TRUE' : 'Authenticated: FALSE') }} className="m-1" label='Is Authenticated' />
          <Button onClick={() => { kc.login() }} className='m-1' label='Login' severity="success" />
          <Button onClick={() => { setInfoMessage(kc.token) }} className="m-1" label='Show Access Token' severity="info" />
          <Button onClick={() => { setInfoMessage(JSON.stringify(kc.tokenParsed)) }} className="m-1" label='Show Parsed Access token' severity="info" />
          <Button onClick={() => { setInfoMessage(kc.isTokenExpired(5).toString()) }} className="m-1" label='Check Token expired' severity="warning" />
          <Button onClick={() => { kc.updateToken(10).then((refreshed)=>{ setInfoMessage('Token Refreshed: ' + refreshed.toString()) }, (e)=>{setInfoMessage('Refresh Error')}) }} className="m-1" label='Update Token (if about to expire)' /> 
          <Button onClick={() => { kc.logout({ redirectUri: 'http://localhost:3000/' }) }} className="m-1" label='Logout' severity="danger" />          
        </div> */}
export default App;
